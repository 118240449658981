<template>
  <div>{{ hour }}:{{ minute }}:{{ second }}</div>
</template>

<script>
export default {
  name: '',
  data () {
    return {
      time: '',
      hours: null,
      minutes: null,
      seconds: null
    }
  },
  methods: {
    TimeNumber (hours, minutes, seconds) {
      this.$set(this, 'hours', hours)
      this.$set(this, 'minutes', minutes)
      this.$set(this, 'seconds', seconds)
      this.add()
    },
    refresh () {
     clearInterval(this.time)
      this.$set(this, 'hours', null)
      this.$set(this, 'minutes', null)
      this.$set(this, 'seconds', null)
    },
    // 防止数值小于10时，出现一位数
    num (n) {
      return n < 10 ? '0' + n : '' + n
    },
    // 倒计时函数
    add () {
      this.time = setInterval(() => {
        if (this.hours !== 0 && this.minutes === 0 && this.seconds === 0) {
          this.hours -= 1
          this.minutes = 59
          this.seconds = 59
        } else if (this.hours === 0 && this.minutes !== 0 && this.seconds === 0) {
          this.minutes -= 1
          this.seconds = 59
        } else if (this.hours === 0 && this.minutes === 0 && this.seconds === 0) {
          this.seconds = 0
          clearInterval(this.time)
        } else if (this.hours !== 0 && this.minutes !== 0 && this.seconds === 0) {
          this.minutes -= 1
          this.seconds = 59
        } else {
          this.seconds -= 1
        }
      }, 1000)
    }
  },
  watch: {
    // 监听数值变化
    second: {
      handler (newVal) {
        this.num(newVal)
      }
    },
    minute: {
      handler (newVal) {
        this.num(newVal)
      }
    },
    hour: {
      handler (newVal) {
        this.num(newVal)
      }
    }
  },
  computed: {
    // 初始化数据
    second () {
      return this.num(this.seconds)
    },
    minute () {
      return this.num(this.minutes)
    },
    hour () {
      return this.num(this.hours)
    }
  }
}
</script>

<style lang="less"></style>
