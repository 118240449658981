<template>
  <div>
    <a-modal v-model="QRCode" title="二维码付款" width="800px" footer @cancel="cancel">
      <a-spin :spinning="loading">
        <div style="width: 100%; height: 100%">
          <h3 style="margin-bottom: 30px">
            请您在
            <countdown ref="countdown" style="display: inline-block; color: red" /> 内完成支付，否则订单将自动取消。
          </h3>
          <p
            style="
              font-size: 24px;
              font-family: .AppleSystemUIFont;
              font-weight: 500;
              line-height: 28px;
              color: #434343;
            "
          >
            支付方式：
          </p>
          <a-row style="margin-bottom: 30px">
            <a-col :span="12" style="text-align: center">
              <div class="div_zf" @click="wx(1)">
                <img src="../../../../public/wx.png" alt="" class="div_zf_img" />
              </div>
            </a-col>
            <a-col :span="12" style="text-align: center">
              <div class="div_zf" @click="zfb(2)">
                <img src="../../../../public/zfb.png" alt="" class="div_zf_img" />
              </div>
            </a-col>
          </a-row>
          <div style="width: 100%; height: 250px; position: relative">
            <vueQr :text="codeUrl" :size="200" class="vueQr"></vueQr>
          </div>
          <div style="width: 100%; text-align: center">
            <h3>{{ title }}</h3>
          </div>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>
<script >
import { weChatPay, zfbChatPay, payNativeStatus } from '@/api/materialShop'
import countdown from './time.vue'
import vueQr from 'vue-qr'
export default {
  name: 'Index',
  components: {
    vueQr,
    countdown
  },
  data () {
    return {
      zfType: 1,
      title: '微信扫描上方二维码，完成支付后，可在“控制台-学习资料”中查看购买的资料。',
      outTradeNo: null,
      codeUrl: '',
      QRCode: false,
      loading: false,
      val: 1,
      row: {}
    }
  },
  watch: {
    // 监听数值变化
    second: {
      handler (newVal) {
        this.num(newVal)
      }
    },
    minute: {
      handler (newVal) {
        this.num(newVal)
      }
    },
    hour: {
      handler (newVal) {
        this.num(newVal)
      }
    },
    QRCode (newName, oldName) {
      if (newName === false) {
        clearInterval(this.timer)
      }
    },
    immediate: true,
    deep: true
  },
  created () {
    clearInterval(this.timer)
  },
  methods: {
    wx (val) {
      if (!(val === this.zfType)) {
        this.zfType = 1
        this.loading = true
        this.title = '微信扫描上方二维码，完成支付后，可在“控制台-学习资料”中查看购买的资料。'
        this.weChatPay(this.outTradeNo)
      }
    },
    zfb (val) {
      if (!(val === this.zfType)) {
        this.zfType = 2
        this.loading = true
        this.title = '支付宝扫描上方二维码，完成支付后，可在“控制台-学习资料”中查看购买的资料。'
        this.zfbChatPay(this.outTradeNo)
      }
    },
    weChatPay (outTradeNo) {
      weChatPay({ outTradeNo })
        .then((res) => {
          this.loading = false
          this.codeUrl = res.data.codeUrl
        })
        .catch(() => {
          this.$message.error('微信二维码提取失败，请重试')
          this.cancel()
        })
    },
    zfbChatPay (outTradeNo) {
      zfbChatPay({ outTradeNo })
        .then((res) => {
          this.loading = false
          this.codeUrl = res.data
        })
        .catch(() => {
          this.$message.error('支付宝二维码提取失败，请重试')
          this.cancel()
        })
    },
    cancel () {
      clearInterval(this.timer)
      this.$nextTick(() => {
        this.$refs.countdown.refresh()
      })
      this.val = 1
      this.zfType = 1
      this.codeUrl = ''
      this.title = '微信扫描上方二维码，完成支付后，可在“控制台-学习资料”中查看购买的资料。'
      this.QRCode = false
      this.loading = false
    },
    setTimer () {
      this.timer = setInterval(() => {
        payNativeStatus({ outTradeNo: this.outTradeNo }).then((res) => {
          if (res.data.status === '1') {
            this.QRCode = false
            this.$message.success('付款成功,请前往资料查看页面中确认商品')
            this.$parent.fatherMethod()
            this.cancel()
          }
        })
      }, 1000)
    },
    // 传参（订单号，创建时间） 计时45分钟
    handleWeChatPay (outTradeNo, createdTime) {
        const date1 = Date.parse(new Date()) / 1000 // 计算当前时间戳
        const date2 = (createdTime + 2700000) / 1000 // 计算当前时间戳
        const date3 = (date2 - date1) * 1000
        if (date2 - date1 > 0) {
            this.$set(this, 'outTradeNo', outTradeNo)
            this.QRCode = true
            this.loading = true
            // 计算出相差天数
            const days = Math.floor(date3 / (24 * 3600 * 1000))
            // 计算出小时数
            const leave1 = date3 % (24 * 3600 * 1000) // 计算天数后剩余的毫秒数
            const hours = Math.floor(leave1 / (3600 * 1000))
            // 计算相差分钟数
            const leave2 = leave1 % (3600 * 1000) // 计算小时数后剩余的毫秒数
            const minutes = Math.floor(leave2 / (60 * 1000))
            // 计算相差秒数
            const leave3 = leave2 % (60 * 1000) // 计算分钟数后剩余的毫秒数
            const seconds = Math.round(leave3 / 1000)
            this.$nextTick(() => {
              this.$refs.countdown.TimeNumber(hours, minutes, seconds)
            })
            this.weChatPay(this.outTradeNo)
            this.setTimer()
        } else {
            this.$message.error('当前订单已过期')
            this.$parent.fatherMethod()
        }
    }
  },
  destroyed () {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>
